import React from 'react';
import './App.css';

function Impressum() {
  return 
}

function App() {
  return (
    <div className="relative w-full h-screen bg-green-200 overflow-hidden">
      {/* Floating Cubicles Animation */}
      <div className="absolute inset-0">
        {Array.from({ length: 20 }).map((_, index) => (
          <div
            key={index}
            className="absolute bg-white opacity-30 rounded-lg animate-float"
            style={{
              width: `${Math.random() * 50 + 30}px`,
              height: `${Math.random() * 50 + 30}px`,
              top: `${Math.random() * 100}vh`,
              left: `${Math.random() * 100}vw`,
              animationDuration: `${Math.random() * 10 + 5}s`,
              animationDelay: `${Math.random() * 3}2`,
            }}
          ></div>
        ))}
      </div>

      {/* Impressum und Datenschutz unten links */}
      {/* <div className="absolute bottom-0 left-0 p-4 text-black text-xs">
        <a href="https://www.moontec.de/impressum" className="hover:underline">Impressum</a> | <a href="https://www.cityup.de/datenschutz" className="hover:underline">Datenschutz</a>
      </div> */}

      {/* Center Text */}
      <div className="flex items-center justify-center h-full text-center">
        <h1 className="text-6xl md:text-9xl font-bold text-white tracking-wider drop-shadow-lg">
          <p className="font-poppins">CityUp</p>
          <button className="mt-10 px-8 py-3 border border-green-300 rounded-lg bg-white bg-opacity-20 text-green-600 text-xl font-light">
            <a href="/Flyer_20241003.pdf" className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 stroke-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M7 10l5 5m0 0l5-5m-5 5V3"
              />
            </svg>
              <span>Unsere Lösung im Detail</span>
            </a>
          </button>
          {/* <div className="w-full h-0.5 bg-white my-20"></div>
          <span className="font-wireone block mt-2 text-5xl md:text-8xl font-light text-white">
            Simple and efficient <br/> renovation strategy management.
          </span> */}
        </h1>
      </div>
    </div>
  );
}

export default App;
